/* FormMobile.css */.form-container-mobile {
  background-color: white;
  padding: 20px; /* Slightly reduced padding for mobile */
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  width: 90%; /* Occupy more screen width on mobile */
  margin: 0 auto; /* Center the form */
  box-sizing: border-box; /* Ensure padding doesn't affect width */
}.input-group-mobile { /* Add -mobile suffix for clarity */
  margin-bottom: 1rem;
}

label {
  display: block;
  margin-bottom: 0.5rem;
  color: #777;
  font-size: 0.9rem; /* Slightly smaller font size for mobile */
}

input[type="text"],
input[type="email"] {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ccc;
  border-radius: 0.375rem;
  box-sizing: border-box;
  font-size: 1rem;
}.react-datepicker__input-container input {
  max-width: 100%; /* Full width on mobile */
  width: 100%;
  text-align: left; /* Align text to the left on mobile */
  color: #632a60;
  padding: 0.5rem 1rem;
  box-sizing: border-box; /* Important! */
}.shadow-button-mobile { /* Add -mobile suffix */
  background-color: #ffffff;
  color: #777;
  padding: 0.5rem 1.5rem; /* Slightly more padding */
  border: 1px solid #ccc;
  border-radius: 0.75rem;
  cursor: pointer;
  font-size: 1rem;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  transition: box-shadow 0.3s ease;
  width: 80px;    
  max-width: 80px;  
  margin-bottom: 1rem; /* Added margin */
  box-sizing: border-box; /* Important! */
}.shadow-button-mobile:hover {
  background-color: #d6d6d6;
  box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.4);
}

/* Optional: Styles for smaller mobile screens */
@media (max-width: 400px) {
  label {
      font-size: 0.8rem;
  }

  input[type="text"],
  input[type="email"],
.react-datepicker__input-container input,
.shadow-button-mobile {
      font-size: 0.9rem;
      padding: 0.6rem; /* Slightly smaller padding */
  }
}
