/* Form.css (optional) */
.form-container {
	background-color: white;
	padding: 20px;
	border-radius: 8px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	width: 50;
}

.input-group {
    margin-bottom: 1rem;
}

label {
	display: block;
	margin-bottom: 0.5rem;
	color: #777;
}

input[type="text"],
input[type="email"] {
    width: 100%;
    min-width: 140px; 
    padding: 0.75rem;
	border: 1px solid #ccc;
    border-radius: 0.375rem;
    box-sizing: border-box;
	position: relative; 
    font-size: 1rem; 
}

.react-datepicker__input-container input {
    max-width: 300px; 
    width: 55%; 
    text-align: center;   
    color: #632a60;
    padding: 0.5rem 1rem;}

.shadow-button {
    background-color: #ffffff;
    color: #777;
    padding: 0.25rem 1rem;
    border: 1px solid #ccc;
    border-radius: 0.75rem;
    cursor: pointer;
    font-size: 1rem;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    transition: box-shadow 0.3s ease;
    position: relative;
    left: 50%;
    transform: translateX(-50%); 
    width: 80px;    
    max-width: 80px; 
    margin-bottom: 0.5rem;

}
.shadow-button:hover {
    background-color: #d6d6d6; /* Example green color */
    box-shadow: 3px 3px 8px rgba(0, 0, 0, 0.4); /* Larger shadow on hover */
}

